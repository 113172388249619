import React, {Component} from 'react';
import {Modal, FormControl, Row, Toast} from 'react-bootstrap';
import '../../stylesheets/share-link.css';
import userIcon from '../../Images/user.png'
import link from '../../Images/link_blue.svg'
import {SHARING_ENDPOINT} from "../../constants/secrets";
import axios from "axios";

import {CopyToClipboard} from 'react-copy-to-clipboard';

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

class ShareLink extends Component
{
    state = {
        showModal:false,
        email:'',
        showEmailError:false,
        copied:false
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value, showEmailError:false });
        if(!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(e.target.value.trim())){
            this.setState({showEmailError:true})
        }
    };

    handleInvite = async () => {
        if(!this.state.showEmailError){
            // api which will send out an email
            // updating the database with newly added email
            let emails = this.props.shared_with;
            emails.push(this.state.email);
            let URL = `${SHARING_ENDPOINT}/share`;
            let data= {
                _id:this.props.id,
                shared_with:emails
            }
            let response = await axios.put(URL, data,config)
            if(response.status){
                // Send email to invite
                let EMAIL_URL = `${SHARING_ENDPOINT}/email`;
                let text = ""
                let images = []
                if(this.props.content.length > 0)
                {
                    let content_element = this.props.content[this.props.content.length-1]
                    if(content_element["type"] == "TEXT")
                    {
                        text = content_element["data"]
                    } 
                    else if(content_element["type"] == "IMAGE")
                    {
                        images.push(content_element["link"])
                    }
                }
                let email_data = {
                    "email":this.state.email,
                    "user_name":this.state.email.split("@")[0],
                    "shared_by":this.props.shared_by,
                    "link":"https://sharing.naya.studio/" + this.props.id,
                    "text": text,
                    "images": images
                }
                console.log(email_data)
                this.setState({email:''})

                let email_response = await axios.post(EMAIL_URL, email_data,config)
                console.log(email_response)
                this.props.refresh();
            }

        }
    }

    render()
    {
        return (
            <div id="share-link">
                <Toast show={this.state.copied} onClose={()=>{this.setState({copied:false})}}>
                    <Toast.Header>
                        <img
                        src="holder.js/20x20?text=%20"
                        className="rounded mr-2"
                        alt=""
                        />
                        <strong className="mr-auto">Link copied to clipboard!</strong>
                    </Toast.Header>
                    </Toast>
                <button className="share-button" onClick={()=>{this.setState({showModal:true})}}>Share Link</button>
                <Modal show={this.state.showModal} onHide={()=>{this.setState({showModal:false})}} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Share</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{width:'100%', justifyContent:'space-between', flexWrap:'no-wrap'}}>
                            <div style={{width:'70%', flex:1, marginRight:'1rem', marginLeft:'1rem'}}>
                            <FormControl
                                aria-describedby='basic-addon1'
                                placeholder='Enter Email Address'
                                name='email'
                                value={this.state.email}
                                onChange={this.handleChange}
                                style={{height:'2.5rem'}}
                            />
                            {
                                this.state.showEmailError && (
                                    <p style={{margin:0, fontSize:10, color:'red'}}>*Invalid Email</p>
                                )
                            }
                            </div>
                            <button className="share-button" style={{height:'2.5rem'}} onClick={this.handleInvite}>Invite</button>
                        </Row>
                        {/* list of invited emails */}
                        <div>
                            {
                                this.props.shared_with.map((email, index) => (
                                    <Row style={{margin: "1rem 0.5rem"}}>
                                        <img src={userIcon} style={{width:'1.5rem', height:'1.5rem', marginRight:'0.5rem'}} alt="user-icon"/>
                                        <p style={{margin:0, flex:1, marginRight:'0.5rem'}}>{email}</p>
                                        <p>Invited</p>
                                    </Row>
                                ))
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <img src={link} alt="link" style={{height:'2rem'}}/>
                        {/* pending: add link to canvas */}
                        <CopyToClipboard text={"link to be copied"} style={{cursor:'pointer', color:'#00A3FF'}}
                            onCopy={() => this.setState({copied: true, showModal:false})}>
                            <span style={{cursor:'pointer', color:'#00A3FF'}}>copy link</span>
                        </CopyToClipboard>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ShareLink