import React, {Component} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import '../../stylesheets/content.css'
import userIcon from '../../Images/user.png'

class Content extends Component
{

    constructor(props)
    {
        super(props)
        this.state = {
            content: this.props.content
        }
    }
    renderImage = (imageSrc) =>
    {
        return <Col xs={12} style={{display:'flex', justifyContent:'center', marginBottom:'1rem'}}>
            <Image src={imageSrc} key={new Date().getTime().toString()} fluid></Image>
        </Col>

    }

    createMarkup = (text) => {
        return {__html: text};
      }

    renderText = (text) =>
    {
        return <Col xs={12} key={new Date().getTime().toString()} dangerouslySetInnerHTML={this.createMarkup(text)}>
            
        </Col>
    }

    renderContent = () =>{
        let renders = []
        // for(let i=0; i<this.props.content.length; i++)
        // {
            let content = this.props.content

            switch(content.type)
            {
                case "IMAGE":
                    renders.push(this.renderImage(content.link))
                    break
                case "TEXT":
                    renders.push(this.renderText(content.data))
                    break
                default:
                    break
            }
        // }
        return renders
    }

    render()
    {
        return <Container className={"shared"}>
            <Row style={{width:'100%', margin:'0.5rem', alignItems:'center'}}>
                    <img src={userIcon} style={{width:'3rem', height:'3rem', marginRight:'0.5rem'}} alt="user-icon"/>
                    <p style={{margin:0, flex:1, marginRight:'0.5rem'}}><span style={{fontWeight:'bold'}}>{this.props.shared_by? this.props.shared_by.split("@")[0]: "Anonymous"}</span> shared {this.props.content.type.toLowerCase()} for feedback</p>
            </Row>
            <Row className="content">
                <Col xs={12}>
                    {this.renderContent()}
                </Col>
            </Row>
        </Container>
    }
}

export default Content