import React from 'react';
import Share from './Pages/Share';
import Login from './components/Auth/Login';
import Redirect from './components/Auth/Redirect';

import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function SharingPortalRouter({ match }) {
  localStorage.setItem("nayaSharingPortalID", match.params.id);
  return <Share id={match.params.id}></Share>;
}

export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/redirect">
            <Redirect />
          </Route>
          <Route path="/login" component={Login}>
            {/* <Login /> */}
          </Route>
          <Route path="/:id" component={SharingPortalRouter}>
            {/* <Share /> */}
          </Route>
          <Route path="/">
            <Share />
          </Route>
        </Switch>
    </Router>
  );
}