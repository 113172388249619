import React from "react";

import { withRouter } from 'react-router-dom';
import axios from "axios";
import { API_ENDPOINT } from "../../constants/secrets";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

class Redirect extends React.Component{
    componentDidMount(){
        if(localStorage.getItem('nayaJwtToken') && localStorage.getItem('nayaJwtToken')!=='undefined'){
            const AUTH_TOKEN_LOCAL_URI = `${API_ENDPOINT}/auth/jwt`;
            let data = localStorage.getItem("nayaJwtToken");
            if (data !== "") {
              axios.get(AUTH_TOKEN_LOCAL_URI, {headers: { Authorization: `Bearer ${data}` },})
              .then((response) => {
                  if (response.data.status) {
                    this.props.history.push("/")
                  } 
              })
              .catch((e) => {
                console.log("login error:", e)
              });
            } 
          }
    }
    render(){
        return(
            <h6>Redirecting...</h6>
        )
    }
}

export default withRouter(Redirect);