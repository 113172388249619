import React, { Component } from 'react'
import {Image, Navbar, NavDropdown} from 'react-bootstrap'
// import {getNotifications, readNotification} from '../../actions/notificationsActions';
import userIcon from '../../Images/user.png'
import notificationIcon from '../../Images/notification.svg'
import '../../stylesheets/navbar.css';
import Prompt from '../prompts/Prompt';
import {API_ENDPOINT} from "../../constants/secrets";
import axios from "axios";

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

class NavBar extends Component{

    state = {
        showNotification: false,
        currentNotification: undefined,
        userId: null,
        notifications:this.props.notifications,
        unread:0,
        user:null,
    }


    componentDidMount(){
      if(localStorage.getItem('nayaJwtToken')){
        const AUTH_TOKEN_LOCAL_URI = `${API_ENDPOINT}/auth/jwt`;
        let data = localStorage.getItem("nayaJwtToken");
        if (data !== "") {
          axios.get(AUTH_TOKEN_LOCAL_URI, {headers: { Authorization: `Bearer ${data}` },})
          .then((response) => {
              if (response.data.status) {
              } 
          })
          .catch((e) => {
            console.log("login error:", e)
          });
        } 
      }
    }


    static getDerivedStateFromProps(nextProps, prevState){
     if(nextProps.notifications.length!==prevState.notifications.length){
         return {notifications:nextProps.notifications}
     }
     return null;
   }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.notifications.length!==this.props.notifications.length){
            this.getUnreadNotificationsCount()
            this.priorityNotification()
        }
    }

    getUnreadNotificationsCount = () => {
        let count=this.state.unread;
        this.state.notifications.map((notif)=>{
            if(notif.readStatus!==true && notif.priority<100){
                count=count+1;
            }
        })
        this.setState({unread:count})
    }

    // function to check the priority of the notifications 
    // if the notification is equal to or above 100, it is displayed immediately on the screen
    priorityNotification = () => {
        let notifs = [];
        this.state.notifications.map((notif)=>{
            if(notif.priority>=100 && !notif.readStatus){
                notifs.push(notif)
            }
        })
        notifs=notifs.slice().sort((a, b) => b.priority - a.priority);
        if(notifs.length!==0){
            this.readNotification(notifs[0]._id,this.props.userId);
            this.passDataToPrompt(notifs[0]);
        }
        
    }
    
    // function to calculate the time difference for the notifications
    timeDifference = (current, previous) => {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
    
        var elapsed = current - previous;
    
        if (elapsed < msPerMinute) {
             return Math.round(elapsed/1000) + ' seconds ago';   
        }
    
        else if (elapsed < msPerHour) {
             return Math.round(elapsed/msPerMinute) + ' mins ago';   
        }
    
        else if (elapsed < msPerDay ) {
             return Math.round(elapsed/msPerHour ) + ' h ago';   
        }
    
        else if (elapsed < msPerMonth) {
            return Math.round(elapsed/msPerDay) + ' days ago';   
        }
    
        else if (elapsed < msPerYear) {
            return  Math.round(elapsed/msPerMonth) + ' months ago';   
        }
    
        else {
            return Math.round(elapsed/msPerYear ) + ' y ago';   
        }
    }

    // Checking if the device is a mobile phone or not
    isMobile = () => {
        if(window.orientation > -1){
            return true 
        }
        else{
            return false
        }
    }

    toggleNotification = () => {
        this.setState({showNotification:!this.state.showNotification})
    }

    // passing data to the notification prompt
    passDataToPrompt = (data) => {
        this.setState({currentNotification:data},this.toggleNotification())
    }


    handleSignOut = (e) =>
    {
        localStorage.setItem('nayaJwtToken', '')
        localStorage.setItem("nayaJwtToken", undefined);
        window.location.href="/login"
    }

    handleAccount = (e) =>
    {
        this.props.history.push('/account')
    }

    readNotification = async(notification_id,userId) => {
      const AUTH_PUT_NOTIFICATION_URI = `${API_ENDPOINT}/api/pushnotification/${notification_id}`
      let response = await axios.put(AUTH_PUT_NOTIFICATION_URI, {"readStatus":true} , config)
      if(response.status){
        this.setState({notifications:[]})
        this.props.refresh()
      }
    }

  renderUserProfile = () => {
    if(this.props.user && this.props.user._id){
      return (
        <Navbar.Text>
          <span>
          {
            !this.isMobile() && (
              <>
              {
                this.props.user.profile_pic ? (
                    <Image src={this.props.user.profile_pic} style={{"height":"3rem", "borderRadius":"5rem", "overflow":"hidden"}} fluid />
                ) : (
                    <Image src={userIcon} style={{"height":"3rem"}} fluid />
                )
                  
              }
              </>
            )
          }
          </span>
          <NavDropdown title={window.orientation > -1 ? <Image src={userIcon} style={{"height":"3rem"}} fluid /> : this.props.user.name ? this.props.user.name:this.props.user.email.split("@")[0]} id="collasible-nav-dropdown" style={{"display":"inline-block"}}>
            <NavDropdown.Item href="" onClick={this.handleAccount}>My Account</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={this.handleSignOut}>Sign Out</NavDropdown.Item>
          </NavDropdown>
          {/* Signed in as: <a href="/account">{this.props.user.email.split("@")[0]}</a> */}
        </Navbar.Text>
      )
    }
    else{
      return <Navbar.Text>
        <a href="/login">Sign Up/Log in</a>
      </Navbar.Text>
    }
  }

  renderNotifications = () => {
    if(this.props.user && this.props.user._id){
      if(this.state.notifications!==undefined || this.state.notifications.length!==0){
        return (
          <Navbar.Text>
            <NavDropdown title={
              <>
              <div style={{position:'absolute', left:37, top:12, "backgroundColor":this.state.unread!==0 && "red", "borderRadius":"5px", "padding":"0.2rem", "width":"10px", "height":"10px","paddingTop":"0.1rem","textAlign":"center", "color":"white", "fontSize":"0.7rem", "fontWeight":"400"}}>
                {/* {this.state.unread!==0 && this.state.unread} */}
                </div>
              <Image src={notificationIcon} style={{height:35, width:35}} fluid />
              </>
              } id="collasible-nav-dropdown" style={{"display":"inline-block", padding:'0px'}}>
              {
                this.state.notifications.length===0 ? <div style={{width:200, paddingTop:20, paddingBottom:20, textAlign:'center', color:'#a3a3a3'}}>No notifications found</div> 
                : 
                this.state.notifications.map((notif, index) => {
                    let date = new Date(notif.date)
                    let now = new Date()
                    let diff = this.timeDifference(now, date)
                    return(
                      <div>
                        <NavDropdown.Item 
                          href="" 
                          style={{ padding:'10px', backgroundColor:notif.readStatus && '#F5F5F5'}} 
                          onClick={()=>{
                            this.passDataToPrompt(notif);
                            this.readNotification(notif._id,this.props.userId); 
                            if(notif.readStatus===false && this.state.unread!==0){
                                this.setState({unread:this.state.unread-1})}}
                            }
                        >
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                          <div>
                            <div style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', width:'200px', fontSize:14}} dangerouslySetInnerHTML={{__html: notif.content.title}}/>
                            <div style={{color:'#a3a3a3', fontSize:12}}>{diff}</div>
                          </div>
                          {
                            !notif.readStatus && <div style={{width:'10px', height:'10px', borderRadius:'5px', backgroundColor:'blue'}}/>
                          }
                        </div>
                      </NavDropdown.Item>
                      {index!==this.state.notifications.length-1 && (<NavDropdown.Divider style={{margin:0}}/>)}
                    </div>
                  )
                })
              }
            </NavDropdown>
          </Navbar.Text>
        )
      }
    }
      
  }

  render(){
    return (
      <>
        <Navbar id="naya-navbar">
          <Navbar.Brand href="https://www.naya.studio/">naya</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
              {this.renderUserProfile()}
              {this.renderNotifications()}
          </Navbar.Collapse>
        </Navbar>
        {
            this.state.showNotification && (<Prompt data={this.state.currentNotification} toggleNotification={this.toggleNotification}/>)
        }
    </>
    )
  }
}
export default NavBar;