import React, {Component} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {Button} from 'react-bootstrap'

import Content from "../components/Content/Content"
import Comment from "../components/Comments/Comment"
import InputComment from "../components/Comments/InputComment"
import ShareLink from "../components/ShareLink/ShareLink"

import userIcon from '../Images/user.png'

import Navbar from '../components/Utilities/Navbar';

import {SHARING_ENDPOINT, API_ENDPOINT} from "../constants/secrets";
import axios from "axios";

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

class Share extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            loading:false,
            shared_by:'',
            shared_with:[],
            content:[],
            comment_ids:[],
            comments: [],
            id:localStorage.getItem("nayaSharingPortalID"),
            user:{},
            notifications:[],
            canvas_id:undefined
        }
        this.commentTileRef = React.createRef();
    }

    async componentDidMount(){
        const AUTH_TOKEN_LOCAL_URI = `${API_ENDPOINT}/auth/jwt`;
        let data = localStorage.getItem("nayaJwtToken");
        try {
            if (data !== "") {
                let response = await axios.get(AUTH_TOKEN_LOCAL_URI, {headers: { Authorization: `Bearer ${data}` },})
                if(response.status){
                    this.setState({user:response.data.user})
                }
            }
        } catch (error) {
            localStorage.setItem('nayaJwtToken', '')
        }
        
        this.getSharedData();
        this.getCanvasName()
    }

    addNewComment = async(comment_id) => {
        let temp = this.state.comment_ids;
        temp.push(comment_id);
        let URL = `${SHARING_ENDPOINT}/share`;
        let data= {
            _id:this.state.id,
            comments:temp
        }
        let response = await axios.put(URL, data,config)
        if(response.status){
            this.setState({comments:[]})
            this.getSharedData();
        }
    }

    getNotifications = async () => {
        const NOTIFICATIONS_URI = `${API_ENDPOINT}/api/pushnotification`;
        console.log(this.state.user)
        if(this.state.user && "_id" in this.state.user){
            let data = {user_id:this.state.user._id}
            let response = await axios.get(NOTIFICATIONS_URI, {params: data} , config )
            if(response.status){
                this.setState({notifications:response.data.notifications.reverse()})
            }
        }
    }

    getCanvasName = async () => {
        const CANVAS_URI = `${API_ENDPOINT}/api`;
        console.log(this.state.user)
        try {
            if(this.state.canvas_id && this.state.canvas_id !== ""){
                let data = {_id:this.state.canvas_id}
                let response = await axios.get(CANVAS_URI, {params: data} , config )
                if(response.status){
                    this.setState({canvas_data:response.data})
                }
            }
        } catch (error) {
            console.log("Error grabbing canvas data")
        }
        
    }

    getSharedData = async () => {
        console.log(SHARING_ENDPOINT)
        let id=localStorage.getItem("nayaSharingPortalID");
        let URL = `${SHARING_ENDPOINT}/share?_id=${id}`;
        let response = await axios.get(URL, config)
        if(response.status){
            this.setState({
                shared_by:      response.data.data.shared_by, 
                shared_with:    response.data.data.shared_with, 
                content:        response.data.data.content,
                comment_ids:    response.data.data.comments,
                canvas_id:      response.data.data.canvas_id
            })
        }
        let comment_ids = response.data.data.comments;
        let comments = [];
        for(let i=0;i<comment_ids.length;i++){
            let GET_COMMENT_URL =  `${API_ENDPOINT}/api/comments`
            let comment_data = await axios.get(GET_COMMENT_URL, {params:{id:comment_ids[i]}}, config)
            comments.push(comment_data.data.comments)
        }
        this.setState({comments:comments})
        this.getNotifications()
        // fetching the notifications after every 10 seconds
        setInterval(() => this.getNotifications(), 10000)
    }

    renderComments = (comments) => 
    {
        let comment_renders = []
        for(let i=0; i<comments.length; i++)
        {
            comment_renders.push(
                <Comment 
                    user={this.state.user}
                    data={comments[i]}
                    refId={comments[i]._id}
                    replyToFunction={this.replyTo}
                >
                </Comment>
            )
        }
        return comment_renders
    }

    replyTo = (comment) => {
        this.setState({ replyTo: comment });
        window.setTimeout(() => {
            this.commentTileRef.scrollIntoView();
        }, 200);
    };

    renderComponentsChronologically = () => 
    {
        let comments = this.state.comments.slice()
        let shared_content = this.state.content.slice()
        let all_content = [...comments, ...shared_content]
        all_content.sort(function(a, b){
            return new Date(a["updatedAt"]).getTime() > new Date(b["updatedAt"]).getTime()
        })

        let RENDERS = []
        for(let i=0; i<all_content.length; i++)
        {
            let content = all_content[i]
            if("type" in content)
            {
                //This is shared content
                RENDERS.push(<Content shared_by={this.state.shared_by} content={content}></Content>)
            }
            else
            {
                RENDERS.push(<Comment 
                    user={this.state.user}
                    data={content}
                    refId={content._id}
                    replyToFunction={this.replyTo}
                >
                </Comment>)
            }
        }
        return RENDERS


    }

    render()
    {
        return (
            <>
            <Navbar user={this.state.user} notifications={this.state.notifications} refresh={this.getSharedData}></Navbar>
            <Container style={{marginTop:'2rem'}}>
                <Row>
                    <Row style={{width:'100%', marginBottom:'2rem', alignItems:'center', justifyContent: 'end'}} className="title-block">
                        {/* <Row style={{flex:1, alignItems:'center', marginLeft:0}}> */}
                            {/* <img src={userIcon} style={{width:'3rem', height:'3rem', marginRight:'0.5rem'}} alt="user-icon"/> */}
                            {/* <p style={{margin:0, flex:1, marginRight:'0.5rem'}}>Sharing Content from Link</p> */}
                        {/* </Row> */}
                        <Col>
                        <p>Sharing from</p>
                        <h2>{this.state.canvas_data && "name" in this.state.canvas_data? this.state.canvas_data.name: "Canvas"}</h2>
                        </Col>
                        <ShareLink 
                            shared_with={this.state.shared_with} 
                            refresh={this.getSharedData} 
                            shared_by={this.state.shared_by}
                            content={this.state.content}
                            id={this.state.id}>

                        </ShareLink>
                    </Row>
                    {/* <Content content={this.state.content}></Content>
                    <div style={{width:'100%', display:'flex', justifyContent:'center', marginBottom:'2rem'}}>
                        <Button 
                            variant="dark" 
                            style={{padding: '1rem 3rem'}} 
                            onClick={()=>{window.location.href=`https://grd.naya.studio/canvas/${this.state.canvas_id}`}}
                            disabled={this.state.canvas_id ? false : true}>Back to Canvas</Button>
                    </div>*/}
                    
                    {this.renderComponentsChronologically()}
                    <h5 style={{width:'100%', fontWeight:'bold', "marginTop":"2rem"}}>Comments:</h5>
                    <Row style={{width:'100%', alignItems:'baseline', marginBottom:'2rem'}}>
                        <InputComment 
                             user={this.state.user}
                            replyTo={this.state.replyTo}
                            addNewComment={this.addNewComment}
                            clearReplyTo={() =>
                                this.setState({ replyTo: undefined })
                            }
                            getRef={(ref) => (this.commentTileRef = ref)}
                        >
                        </InputComment>
                        {/* {this.renderComments(this.state.comments.reverse())} */}
                    </Row> 
                </Row>
            </Container>
            </>
        )
    }
}

export default Share;