let API_ENDPOINT;
let UPLOAD_ENDPOINT;
let SHARING_ENDPOINT;
if (process.env.REACT_APP_STAGING) {
    API_ENDPOINT = "https://naya-app-backend-staging-ziylmcndpq-ue.a.run.app";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-staging-ziylmcndpq-ue.a.run.app/upload";
} else if (process.env.NODE_ENV === "production") {
    API_ENDPOINT = "https://api.naya.studio";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-production-ziylmcndpq-ue.a.run.app/upload";
        SHARING_ENDPOINT = "https://naya-sharing-backend-ziylmcndpq-ue.a.run.app/";
} else {
    API_ENDPOINT = "http://localhost:3005";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-development-ziylmcndpq-ue.a.run.app/upload";
    SHARING_ENDPOINT = "http://localhost:5000";
}

export {
    API_ENDPOINT,
    UPLOAD_ENDPOINT,SHARING_ENDPOINT
};
