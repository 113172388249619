import React, { Component } from "react";
// import { postComment } from "../../actions/commentActions";
import { Row, Col, Button, Form, Image } from "react-bootstrap";
import CanvasDraw from "react-canvas-draw";
// import axios from "axios";
import { css } from "@emotion/core";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
// import { API_ENDPOINT } from "../../constants/secrets";
import SyncLoader from "react-spinners/SyncLoader";
import userIcon from '../../Images/user.png'
import '../../stylesheets/comments.css';
import { API_ENDPOINT} from "../../constants/secrets";
import axios from "axios";
import { withRouter } from 'react-router-dom';

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*"    }
  }
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const spinnerStyle = css`
    margin-left: 50%;
    transform: translate(-50%, 0);
`;
class CommentTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cols: 1,
            showTextInput: false,
            nImgs: 0,
            imgs: [],
            inputText: "",
            clientWidth: 100,
            user:{},
            replyTo:undefined,
        };
        this.commentImage = React.createRef();
    }

    async componentDidMount() {
        if (this.divElement) {
            this.setState({ clientWidth: this.divElement.clientWidth });
        }
    }

    getCols = () => {
        if (this.state.cols >= 4) {
            return 4;
        } else {
            return this.state.cols;
        }
    };

    handleSubmit = async(e) => {
        let data = {
            // canvas_id: this.props.canvas._id,
            user: this.props.user._id,
            comment_text: this.state.inputText,
            comment_image_uploads: this.state.imgs.map((upload) => upload.id),
        };

        if (this.props.replyTo) {
            data["data_linked"] = this.props.replyTo;
        }
        if (this.state.inputText !== "" || this.state.imgs.length !== 0) {
            const POST_COMMENT_URI = `${API_ENDPOINT}/api/comments`;
            let response = await axios.post(POST_COMMENT_URI, data, config)
            if(response.status){
                this.props.addNewComment(response.data.comment._id)
            }
            this.props.clearReplyTo();
            this.setState({
                cols: 1,
                showTextInput: false,
                nImgs: 0,
                imgs: [],
                inputText: "",
            });
        }
    };

    addTextBox = (e) => {
        e.preventDefault();
        this.setState({ cols: this.state.cols + 1, showTextInput: true });
    };

    handleImageUpload = async(e) => {
        e.preventDefault();
        var file = this.commentImage.current.files[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`;
        this.setState({ isImageUploading: true });
        let response = await axios
            .post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true });
        if(response.status){
            this.addImageBox(response.data);
            this.setState({isImageUploading:false})
        }
    };

    addImageBox = (upload) => {
        let nImgs = this.state.nImgs + 1;
        let imgs = [...this.state.imgs, upload];
        this.setState({ nImgs, imgs, cols: this.state.cols + 1 });
    };

    renderImageBox = (upload) => {
        let cols = this.getCols();

        if (this.props.replyTo !== undefined) {
            cols = cols + 1;
        }

        return (
            <Col
                xs={12}
                md={cols < 2 ? 12 / cols : 6}
                lg={cols < 4 ? 12 / cols : 4}
                xl={12 / cols}
                style={{
                    minHeight: "300px",
                    padding: "0",
                    backgroundColor: "white",
                }}
            >
                <Image src={upload.link} fluid></Image>
            </Col>
        );
    };

    handleTextChange = (e) => {
        this.setState({ inputText: e });
    };

    renderUserProfile = () => {
        let render = null;
        try {
            let name = "Anonymous";
            if (this.props.user && this.props.user.email) {

                name = this.props.user.email.split("@")[0];
            }

            if (this.props.user && this.props.user.name) {

                name = this.props.user.name;
            }

            // let name = (this.props.user.name !== undefined && this.props.user.name !== '')? this.props.user.name : this.props.user.email.split('@')[0]
            let profile_pic = this.props.user.profile_pic;
            let diff = "Just now";

            render = (
                <Col xs={12} className="user-profile">
                    <Row>
                        <Image src={profile_pic}></Image>
                        <Col xs={10}>
                            <p className="name">{name}</p>
                            <p className="time">{diff}</p>
                        </Col>
                    </Row>
                </Col>
            );
        } catch (error) {
            render = (
                <Col xs={12} className="user-profile">
                    <Row>
                        <Image src={userIcon}></Image>
                        <Col xs={10}>
                            <p className="name">Anonymous</p>
                            <p className="time">Just now</p>
                        </Col>
                    </Row>
                </Col>
            )
            // render = <p>{this.props.user.email}</p>;
        }
        return render;
    };

    renderContent = () => {
        let render;
        switch (this.props.contentType) {
            case "TEXT":
                render = <h5 style={{fontFamily:'bigmoore'}}>{this.props.text}</h5>;
                break;
            case "IMAGE":
                render = <Image src={this.props.image}></Image>;
                break;
            case "ANSWER":
                render = (
                    <Col xs={12}>
                        <h5 style={{ textAlign: "center", fontFamily:'bigmoore' }}>
                            {this.props.data.question !== null &&
                                this.props.data.question.main}
                        </h5>
                        <Row className="justify-content-md-center">
                            {this.props.data.options.map((option) => (
                                <Col
                                    key={option.text + option.link}
                                    xs={6}
                                    lg={6}
                                >
                                    <Image src={option.link} fluid></Image>
                                    <p className="option-text">{option.text}</p>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                );
                break;
            case "TEXT_MOODBOARD":
                render = (
                    <Col xs={12}>
                        <h5 style={{ textAlign: "center" }}>Furniture Style</h5>
                        <Row className="justify-content-md-center">
                            {this.props.data.options.map((option) => (
                                <Col key={option} xs={6}>
                                    <p className="option-text">{option}</p>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                );
                break;
            case "VISUAL_MOODBOARD":
                render = (
                    <Col xs={12}>
                        <Image src={this.props.data} fluid></Image>
                    </Col>
                );
                break;

            case "UPLOAD":
                render = (
                    <Col xs={12}>
                        <Image src={this.props.data.link}></Image>
                    </Col>
                );
                break;
            case "SKETCH":
                render = (
                    <Col
                        xs={12}
                        ref={(divElement) => (this.divElement = divElement)}
                        style={{ minHeight: "100%" }}
                    >
                        <CanvasDraw
                            className="canvas-sketch"
                            disabled
                            canvasWidth={this.state.clientWidth}
                            canvasHeight={this.state.clientWidth}
                            ref={(canvasDraw) =>
                                (this.loadableCanvas = canvasDraw)
                            }
                            saveData={this.props.data.sketchData}
                        />
                    </Col>
                );
                break;
            case "COMMENT":
                render = (
                    <Col
                        xs={12}
                        className={this.state.className + " reply-to"}
                    >
                        {/* <h6>Replying to</h6>
                        <Col xs={12}>
                            <Row>
                                <Col xs={12}>
                                    <h5 style={{ textAlign: "center" , fontFamily:'sofia-pro', wordBreak:'break-word'}}>
                                        <div className="line-clamp" dangerouslySetInnerHTML={{__html:replyTo.text}}></div>
                                    </h5>
                                </Col>
                            </Row>
                        </Col> */}
                    </Col>
                );
                break;
            default:
                break;
        }
        return render;
    };

    renderReplyTo = () => {
        let cols = this.getCols();

        if (this.props.replyTo !== undefined) {
            cols = cols + 1;
        }

        let render = <div></div>;
        try {
            let replyTo = this.props.replyTo;

            switch (replyTo.type) {
                case "SKETCH":
                    render = (
                        <Col
                            xs={12}
                            md={cols < 2 ? 12 / cols : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={this.state.className + " reply-to"}
                        >
                            <h6>Replying to</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Col
                                        xs={12}
                                        ref={(divElement) =>
                                            (this.divElement = divElement)
                                        }
                                        style={{ minHeight: "100%" }}
                                    >
                                        <CanvasDraw
                                            className="canvas-sketch"
                                            disabled
                                            canvasWidth={this.state.clientWidth}
                                            canvasHeight={
                                                this.state.clientWidth
                                            }
                                            ref={(canvasDraw) =>
                                                (this.loadableCanvas = canvasDraw)
                                            }
                                            saveData={replyTo.image}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                case "IMAGE":
                    render = (
                        <Col
                            xs={12}
                            md={cols < 2 ? 12 / cols : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={this.state.className + " reply-to"}
                        >
                            <h6>Replying to</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Image src={replyTo.image} fluid></Image>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                case "PINTEREST":
                    render = (
                        <Col
                            xs={12}
                            md={cols < 2 ? 12 / cols : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={this.state.className + " reply-to"}
                        >
                            <h6>Replying to</h6>
                            <Col xs={12} className="canvas-tile">
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                <a href={this.props.pinterest}>
                                    <i className='fa fa-pinterest fa-3x' aria-hidden='true' style={{color: "#c8232c",marginRight: 10,fontSize:'10rem'}}></i>
                                </a>
                            </div>
                            </Col>
                        </Col>
                    );
                    break;
                case "TEXT_IMG":
                    render = (
                        <Col
                            xs={12}
                            md={cols < 2 ? 12 / cols : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={this.state.className + " reply-to"}
                        >
                            <h6>Replying to</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Col xs={12}>
                                        <h5 style={{ textAlign: "center" }}>
                                            <div dangerouslySetInnerHTML={{__html:replyTo.text}}></div>
                                        </h5>
                                        <Image
                                            src={replyTo.image}
                                            fluid
                                        ></Image>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                case "TEXT":
                    render = (
                        <Col
                            xs={12}
                            md={cols < 2 ? 12 / cols : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={this.state.className + " reply-to"}
                        >
                            <h6>Replying to</h6>
                            <Col xs={12} className="canvas-tile">
                                <Row>
                                    <Col xs={12}>
                                        <h5 style={{ textAlign: "center" }}>
                                            <div dangerouslySetInnerHTML={{__html:replyTo.text}}></div>
                                        </h5>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                case "COMMENT":
                    render = (
                        <Col
                            xs={12}
                            md={cols < 2 ? 12 / cols : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className={this.state.className + " reply-to"}
                        >
                            <h6>Replying to</h6>
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <h5 style={{ textAlign: "center" , fontFamily:'sofia-pro', wordBreak:'break-word'}}>
                                            <div className="line-clamp" dangerouslySetInnerHTML={{__html:replyTo.text}}></div>
                                        </h5>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    );
                    break;
                default:
                    render = <div></div>;
            }
        } catch (error) {
            render = <div></div>;
        }

        return render;
    };

    render() {
        let cols = this.getCols();
        let replyTo = this.props.replyTo;

        if (this.props.replyTo === undefined) {
            replyTo = <div></div>;
        } else {
            replyTo = this.renderReplyTo();
            cols = cols + 1;
        }

        let render = (
            <Col
                xs={12}
                md={cols * 6}
                lg={cols * 4}
                xl={cols * 3}
                style={{ padding: "0.5rem" }}
                id='comments'
            >
                <Col xs={12}>{this.renderUserProfile()}</Col>
                <Col xs={12} className="comment-tile">
                    <Row>
                        <Col
                            xs={12}
                            md={cols < 2 ? 12 / cols : 6}
                            lg={cols < 4 ? 12 / cols : 4}
                            xl={12 / cols}
                            className="add-content-tile"
                        >
                            <Row>
                                <Col xs={{ span: 12 }}>
                                    <Button
                                        onClick={() =>
                                            this.props.history.push("/login")
                                        }
                                    >
                                        Login
                                    </Button>
                                </Col>
                                <Col xs={{ span: 12 }}>
                                    <Button
                                        onClick={() =>
                                            this.props.history.push("/login")
                                        }
                                    >
                                        Sign Up
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Col>
        );

        if (this.props.user && this.props.user.email) {
            render = (
                <Col
                    xs={12}
                    md={cols * 6}
                    lg={cols * 4}
                    xl={cols * 3}
                    style={{ padding: "0.5rem" }}
                >
                    <Col xs={12}>{this.renderUserProfile()}</Col>
                    <Col xs={12} className="comment-tile">
                        <Row>
                            {replyTo}
                            {this.state.showTextInput && (
                                <Col
                                    xs={12}
                                    md={cols < 2 ? 12 / cols : 6}
                                    lg={cols < 4 ? 12 / cols : 4}
                                    xl={12 / cols}
                                    style={{
                                        minHeight: "300px",
                                        backgroundColor: "white",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                    }}
                                >
                                    <ReactQuill
                                        value={this.state.inputText}
                                        style={{
                                            height: "100%",
                                            fontSize: "1rem",
                                            border: "none",
                                            outline: "none",
                                        }}
                                        onChange={this.handleTextChange}
                                    />
                                </Col>
                            )}

                            {this.state.nImgs > 0 &&
                                this.state.imgs.map((img) =>
                                    this.renderImageBox(img)
                                )}

                            <Col
                                xs={12}
                                md={cols < 2 ? 12 / cols : 6}
                                lg={cols < 4 ? 12 / cols : 4}
                                xl={12 / cols}
                                className="add-content-tile"
                            >
                                <Row>
                                    <Col xs={{ span: 12 }}>
                                        <Button onClick={this.addTextBox}>
                                            Add Text
                                        </Button>
                                    </Col>
                                    <Col xs={{ span: 12 }}>
                                        <Button onClick={this.uploadImage}>
                                            <label
                                                htmlFor="comment-image"
                                                className="profile-pic-wrapper"
                                                style={{marginBottom:0}}
                                            >
                                                {this.props.uploading ? (
                                                    <SyncLoader
                                                        css={spinnerStyle}
                                                        color={"#000000"}
                                                        sizeUnit={"em"}
                                                        size={"1"}
                                                    ></SyncLoader>
                                                ) : (
                                                    "Upload Images"
                                                )}
                                                <Form.Control
                                                    type="file"
                                                    ref={this.commentImage}
                                                    onChange={
                                                        this.handleImageUpload
                                                    }
                                                    id="comment-image"
                                                    style={{
                                                        visibility: "hidden",
                                                        position: "absolute",
                                                    }}
                                                ></Form.Control>
                                            </label>
                                        </Button>
                                    </Col>
                                    <Col xs={{ span: 12 }}>
                                        <Button
                                            onClick={this.handleSubmit}
                                            style={{
                                                backgroundColor: "black",
                                                color: "white",
                                            }}
                                        >
                                            Post
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            );
        }

        return [render, <div ref={(ref) => this.props.getRef(ref)}></div>];
    }
}

export default withRouter(CommentTile);
